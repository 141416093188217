import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';

export default function AuthGuard({ component }) {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <div>{/* <PageLoader /> */}</div>,
  });

  return <Component />;
}
