import { createSelector } from '@reduxjs/toolkit';

import { ausrehabApi } from './api';

const selectRolsResult = ausrehabApi.endpoints.getRoles.select();

// see https://redux.js.org/usage/deriving-data-selectors#createselector-overview
// for how create Selector works
export const selectSupplierRoleId = createSelector(selectRolsResult, (roles) => {
  if (!roles.data) {
    return undefined;
  }

  const role = roles.data.find((role) => role.role === 'SUPPLIER');

  return role.id;
});
