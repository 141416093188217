import * as Yup from 'yup';

const EmailTemplate = {
  schema: Yup.object().shape({
    name: Yup.string()
      .default('')
      .required('Required'),
    subject: Yup.string()
      .default('')
      .required('Required'),
    bodyHtml: Yup.string()
      .default('')
      .required('Required'),
  }),
};

export default EmailTemplate;
