let getAccessTokenSilently = null;

const tokenManager = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: func => {
    getAccessTokenSilently = func;
  },
};

export default tokenManager;
