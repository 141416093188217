import React, { useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Card, LinearProgress, CardContent, Grid, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import Supplier from '../../utils/supplier';
import { useGetSupplierQuery, useUpdateSupplierMutation } from '../../slices/api';
import Tests from '../../sections/@dashboard/request/Tests';

export default function SupplierEdit() {
  const { supplierId } = useParams();
  const navigate = useNavigate();
  const [updateSupplier, { isSuccess, isError }] = useUpdateSupplierMutation();

  const { data: supplier, isLoading: isLoadingSupplier } = useGetSupplierQuery(supplierId);

  const formik = useFormik({
    initialValues: useMemo(() => {
      if (supplier) {
        return Supplier.schema.cast({
          ...supplier,
        });
      }

      return Supplier.schema.cast();
    }, [supplier]),
    enableReinitialize: true,
    validationSchema: Supplier.schema,
    onSubmit: values => {
      updateSupplier({
        ...values,
        testIds: Supplier.formatTestsPayload(values.tests),
      });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      navigate('/dashboard/admin/suppliers');
    }
    if (isError) {
      formik.setSubmitting(false);
    }
  }, [formik, isError, isSuccess, navigate]);

  if (isLoadingSupplier) {
    return <LinearProgress />;
  }

  return (
    <>
      <Helmet>
        <title> Supplier </title>
      </Helmet>
      <Grid container justifyContent="start">
        <Grid item md={6}>
          <Typography variant="h4" gutterBottom>
            Edit a supplier
          </Typography>
          <Card md={{ m: 2 }}>
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="name"
                      name="name"
                      label="Name"
                      variant="standard"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="phone"
                      name="phone"
                      label="Phone"
                      variant="standard"
                      inputProps={{ inputMode: 'numeric', maxLength: 10 }}
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      error={formik.touched.phone && Boolean(formik.errors.phone)}
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="email"
                      name="email"
                      label="Email"
                      variant="standard"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="address"
                      name="address"
                      label="Address"
                      variant="standard"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      error={formik.touched.address && Boolean(formik.errors.address)}
                      helperText={formik.touched.address && formik.errors.address}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Tests formik={formik} showFormLabel />
                  </Grid>
                  <Grid item md={4}>
                    <Grid container justifyContent="start">
                      <Grid item md={6}>
                        <LoadingButton loading={formik.isSubmitting} variant="outlined" color="primary" type="submit">
                          <span>Update</span>
                        </LoadingButton>
                      </Grid>
                      <Grid item md={6}>
                        <Button color="error" variant="outlined" onClick={() => navigate('/dashboard/admin/suppliers')}>
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
