import { TextField, Grid, MenuItem } from '@mui/material';
import { getIn } from 'formik';
import { state } from '../../../utils/config';

const Address = props => {
  const { formik } = props;

  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <TextField
          fullWidth
          margin="dense"
          id="addrline"
          name="candidate.addrLine"
          label="Address"
          variant="standard"
          value={formik.values.candidate.addrLine}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={Boolean(getIn(formik.touched, 'candidate.addrLine') && getIn(formik.errors, 'candidate.addrLine'))}
          helperText={getIn(formik.touched, 'candidate.addrLine') && getIn(formik.errors, 'candidate.addrLine')}
        />
      </Grid>

      <Grid item md={6}>
        <TextField
          fullWidth
          margin="dense"
          id="addrsuburb"
          name="candidate.addrSuburb"
          label="Suburb"
          variant="standard"
          value={formik.values.candidate.addrSuburb}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={Boolean(getIn(formik.touched, 'candidate.addrSuburb') && getIn(formik.errors, 'candidate.addrSuburb'))}
          helperText={getIn(formik.touched, 'candidate.addrSuburb') && getIn(formik.errors, 'candidate.addrSuburb')}
        />
      </Grid>
      <Grid item md={6}>
        <TextField
          fullWidth
          margin="dense"
          id="addrpostcode"
          name="candidate.addrPostcode"
          label="Postcode"
          variant="standard"
          inputProps={{ inputMode: 'numeric', maxLength: 4 }}
          value={formik.values.candidate.addrPostcode}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={Boolean(
            getIn(formik.touched, 'candidate.addrPostcode') && getIn(formik.errors, 'candidate.addrPostcode')
          )}
          helperText={getIn(formik.touched, 'candidate.addrPostcode') && getIn(formik.errors, 'candidate.addrPostcode')}
        />
      </Grid>
      <Grid item md={6}>
        <TextField
          select
          fullWidth
          margin="dense"
          id="addrstate"
          name="candidate.addrState"
          label="State"
          variant="standard"
          value={formik.values.candidate.addrState}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={Boolean(getIn(formik.touched, 'candidate.addrState') && getIn(formik.errors, 'candidate.addrState'))}
          helperText={getIn(formik.touched, 'candidate.addrState') && getIn(formik.errors, 'candidate.addrState')}
        >
          {state.map(s => (
            <MenuItem key={s.name} value={s.label}>
              {s.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default Address;
