export const state = [
  {
    name: 'nsw',
    label: 'NSW',
  },
  {
    name: 'vic',
    label: 'VIC',
  },
  {
    name: 'qld',
    label: 'QLD',
  },
  {
    name: 'wa',
    label: 'WA',
  },
  {
    name: 'sa',
    label: 'SA',
  },
  {
    name: 'tas',
    label: 'TAS',
  },
  {
    name: 'act',
    label: 'ACT',
  },
  {
    name: 'nt',
    label: 'NT',
  },
];

export const fileMime = {
  pdf: '.pdf',
  doc: '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

export const baseUrl = process.env.REACT_APP_PES_API_URL;
