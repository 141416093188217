import { Helmet } from 'react-helmet-async';
import * as React from 'react';
import { Button, Card, CardHeader, CardContent, Grid, Typography, Paper, LinearProgress, Stack } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

import { useGetSupplierQuery } from '../../slices/api';
import { fCurrency } from '../../utils/formatNumber';

const SupplierView = () => {
  const { supplierId } = useParams();
  const navigate = useNavigate();
  const { data: supplier } = useGetSupplierQuery(supplierId);

  if (!supplier) {
    return <LinearProgress />;
  }

  return (
    <>
      <Helmet>
        <title> View Supplier - {process.env.REACT_APP_ORG} </title>
      </Helmet>
      <Paper elevation={6}>
        <Card>
          <CardHeader
            action={
              <Stack spacing={2} direction="row">
                <Button variant="outlined" color="error" onClick={() => navigate('/dashboard/admin/suppliers')}>
                  Back
                </Button>
              </Stack>
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Name
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {supplier.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Phone
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {supplier.phone}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Email
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {supplier.email}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Address
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {supplier.address}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Available Tests
                </Typography>
                {supplier.tests.map(test => {
                  return (
                    <Grid container spacing={2} key={test.id} columns={4}>
                      <Grid item xs={1}>
                        <Typography variant="body1">{test.name}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="subtitle2">{fCurrency(test.price)}</Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </>
  );
};

export default SupplierView;
