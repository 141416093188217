import { createSlice } from '@reduxjs/toolkit';

const initialState = { error: null };

export const name = 'error';

const errorSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    setError(state, action) {
      const { payload } = action;

      state.error = {
        error: payload.error,
        status: payload.status,
      };
    },
  },
});

export const { clearError, setError } = errorSlice.actions;
export default errorSlice.reducer;
