import { TextField, Grid } from '@mui/material';
import { getIn } from 'formik';

const PersonalInfo = props => {
  const { formik } = props;

  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <TextField
          fullWidth
          margin="dense"
          id="firstname"
          name="candidate.firstname"
          label="First Name"
          variant="standard"
          value={formik.values.candidate.firstname}
          onChange={formik.handleChange}
          error={Boolean(getIn(formik.touched, 'candidate.firstname') && getIn(formik.errors, 'candidate.firstname'))}
          helperText={getIn(formik.touched, 'candidate.firstname') && getIn(formik.errors, 'candidate.firstname')}
        />
      </Grid>
      <Grid item md={6}>
        <TextField
          fullWidth
          margin="dense"
          id="lastname"
          name="candidate.lastname"
          label="Last Name"
          variant="standard"
          value={formik.values.candidate.lastname}
          onChange={formik.handleChange}
          error={Boolean(getIn(formik.touched, 'candidate.lastname') && getIn(formik.errors, 'candidate.lastname'))}
          helperText={getIn(formik.touched, 'candidate.lastname') && getIn(formik.errors, 'candidate.lastname')}
        />
      </Grid>
      <Grid item md={6}>
        <TextField
          fullWidth
          margin="dense"
          id="phone"
          name="candidate.phone"
          label="Phone"
          variant="standard"
          inputProps={{ inputMode: 'numeric', maxLength: 10 }}
          value={formik.values.candidate.phone}
          onChange={formik.handleChange}
          error={Boolean(getIn(formik.touched, 'candidate.phone') && getIn(formik.errors, 'candidate.phone'))}
          helperText={getIn(formik.touched, 'candidate.phone') && getIn(formik.errors, 'candidate.phone')}
        />
      </Grid>
      <Grid item md={6}>
        <TextField
          fullWidth
          margin="dense"
          id="email"
          name="candidate.email"
          label="Email"
          variant="standard"
          type="email"
          value={formik.values.candidate.email}
          onChange={formik.handleChange}
          error={Boolean(getIn(formik.touched, 'candidate.email') && getIn(formik.errors, 'candidate.email'))}
          helperText={getIn(formik.touched, 'candidate.email') && getIn(formik.errors, 'candidate.email')}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          margin="dense"
          id="employer"
          name="candidate.employer"
          label="Employer"
          variant="standard"
          value={formik.values.candidate.employer}
          onChange={formik.handleChange}
          error={Boolean(getIn(formik.touched, 'candidate.employer') && getIn(formik.errors, 'candidate.employer'))}
          helperText={getIn(formik.touched, 'candidate.employer') && getIn(formik.errors, 'candidate.employer')}
        />
      </Grid>
    </Grid>
  );
};

export default PersonalInfo;
