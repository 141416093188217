import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Editor from 'react-simple-code-editor';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardContent, Grid, TextField, FormControl, FormHelperText } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { highlight, languages } from 'prismjs/components/prism-core';

import { useTheme } from '@mui/material/styles';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism.css';

import './EmailTemplateStyle.css';

import EmailTemplate from '../../utils/emailTemplate';
import { useAddEmailTemplateMutation } from '../../slices/api';

export default function EmailTemplateNew() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [addEmailTemplate, { isSuccess, isError }] = useAddEmailTemplateMutation();

  const formik = useFormik({
    initialValues: EmailTemplate.schema.cast(),
    validationSchema: EmailTemplate.schema,
    onSubmit: values => {
      addEmailTemplate(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      navigate('/dashboard/admin/email-templates');
    }
    if (isError) {
      formik.setSubmitting(false);
    }
  }, [formik, navigate, isSuccess, isError]);

  return (
    <>
      <Helmet>
        <title> New Email Template - {process.env.REACT_APP_ORG} </title>
      </Helmet>
      <Grid container justifyContent="start">
        <Grid item md={6}>
          <Card md={{ m: 2 }}>
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="name"
                      name="name"
                      label="Name"
                      variant="standard"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="subject"
                      name="subject"
                      label="Subject"
                      variant="standard"
                      type="subject"
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                      error={formik.touched.subject && Boolean(formik.errors.subject)}
                      helperText={formik.touched.subject && formik.errors.subject}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <FormControl fullWidth error={formik.touched.bodyHtml && Boolean(formik.errors.bodyHtml)}>
                      <Editor
                        id="bodyHtml"
                        name="bodyHtml"
                        value={formik.values.bodyHtml}
                        onValueChange={val => formik.setFieldValue('bodyHtml', val)}
                        highlight={code => highlight(code, languages.markup)}
                        padding={10}
                        style={{
                          fontFamily: '"Fira code", "Fira Mono", monospace',
                          fontSize: 12,
                          border: `1.5px ${theme.palette.grey[400]} solid`,
                          borderRadius: '4px',
                        }}
                        textareaClassName="email-template-editor"
                      />
                      <FormHelperText sx={{ ml: 0 }}>
                        {formik.touched.bodyHtml && formik.errors.bodyHtml}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <Grid container justifyContent="start">
                      <Grid item md={6}>
                        <LoadingButton loading={formik.isSubmitting} variant="outlined" color="primary" type="submit">
                          <span>Save</span>
                        </LoadingButton>
                      </Grid>
                      <Grid item md={6}>
                        <Button
                          color="error"
                          variant="outlined"
                          onClick={() => navigate('/dashboard/admin/email-templates')}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
