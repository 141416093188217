import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { ausrehabApi } from './slices/api';
import authSliceReducer from './slices/auth';
import paginationSliceReducer from './slices/pagination';
import errorSlice, { name as errorSliceName } from './slices/error';

const store = configureStore({
  reducer: {
    [ausrehabApi.reducerPath]: ausrehabApi.reducer,
    auth: authSliceReducer,
    pagination: paginationSliceReducer,
    [errorSliceName]: errorSlice,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(ausrehabApi.middleware),
});

setupListeners(store.dispatch);

export default store;
