import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Alert, AlertTitle, Typography } from '@mui/material';

export default function AuthorizationGuard({ Component, everyoneAccess, userAccess, supplierAccess, adminAccess, backwardRoute }) {
  const { user } = useAuth0();

  const isAdmin = user?.user_roles?.includes('ADMIN');
  const isUser = user?.user_roles?.includes('USER');
  const isSupplier = user?.user_roles?.includes('SUPPLIER');

  const denyView = (
    <Alert severity="error">
      <AlertTitle sx={{ typography: 'h6' }}>Error</AlertTitle>
      <Typography>You don't have sufficient permissions to access this page.</Typography>
    </Alert>
  );

  if (isAdmin || everyoneAccess) {
    return <Component {...{ isAdmin, isUser, isSupplier, backwardRoute }} />;
  }

  if (adminAccess) {
    return denyView;
  }

  if (userAccess) {
    return isUser ? <Component {...{ isAdmin, isUser, isSupplier, backwardRoute }} /> : denyView;
  }

  if (supplierAccess) {
    return isSupplier ? <Component {...{ isAdmin, isUser, isSupplier, backwardRoute }} /> : denyView;
  }
}
