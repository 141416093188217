import { Card } from '@mui/material';

const SettingsCard = (props) => (
	<Card
		sx={{
			':hover': {
				boxShadow: 20,
				cursor: 'pointer',
			},
			height: '100%',
		}}
		onClick={props.onClick}
	>
		{props.children}
	</Card>
);

export default SettingsCard;
