import { Helmet } from 'react-helmet-async';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const { user } = useAuth0();

  return (
    <>
      <Helmet>
        <title> Dashboard - {process.env.REACT_APP_ORG} </title>
      </Helmet>

      <Container maxWidth="xl">
        {user ? (
          <Typography variant="h4" sx={{ mb: 5 }}>
            Hi, {user.firstname}
          </Typography>
        ) : (
          <Typography variant="h4" sx={{ mb: 5 }}>
            Hi, Guest
          </Typography>
        )}
      </Container>
    </>
  );
}
