import * as Yup from 'yup';

const Test = {
  schema: Yup.object().shape({
    name: Yup.string()
      .default('')
      .required('Required'),
    desc: Yup.string()
      .default('')
      .nullable(),
    price: Yup.number()
      .positive()
      .default(0)
      .required('Required'),
  }),
};

export default Test;
