import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormHelperText,
  FormGroup,
  Skeleton,
  Stack,
  Box,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Request from '../../../utils/request';
import { useGetTestsQuery } from '../../../slices/api';

const Tests = ({ formik, showFormLabel = false }) => {
  const { data: tests } = useGetTestsQuery();
  const theme = useTheme();

  const ifTestChecked = Request.ifMarkTestChecked(formik.values.tests);

  const onTestChange = e => {
    const newValue = e.target.checked
      ? [
        ...formik.values.tests,
        {
          id: e.target.name,
        },
      ]
      : formik.values.tests.filter(test => Number(test.id) !== Number(e.target.name));


    formik.setValues({
      ...formik.values,
      tests: newValue,
      // reset test centre when we change tests
      supplierId: ''
    })
  };

  const ifError = Boolean(formik.touched.tests) && Boolean(formik.errors.tests);

  const subtotal = tests
    ? formik.values.tests.reduce((sum, selectedTest) => {
      const test = tests.find(test => Number(test.id) === Number(selectedTest.id));
      return sum + (test ? test.price : 0);
    }, 0)
    : 0;

  return (
    <>
      {!tests ? (
        <Stack sx={{ width: '40%' }} spacing={2}>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
        </Stack>
      ) : (
        <FormControl margin="normal" component="fieldset" variant="standard" error={ifError}>
          {showFormLabel && <FormLabel component="legend">Tests</FormLabel>}
          {ifError && <FormHelperText>{formik.errors.tests}</FormHelperText>}
          <FormGroup>
            {tests.map(test => (
              <FormControlLabel
                key={test.id}
                control={<Checkbox name={test.id.toString()} checked={ifTestChecked(test)} onChange={onTestChange} />}
                label={
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', minWidth: '300px' }}>
                    <span>{test.name}</span>
                    <span>${test.price}</span>
                  </Box>
                }
              />
            ))}
          </FormGroup>

          {formik.values.tests.length > 0 && (
            <Box
              sx={{
                width: '98%',
                justifyContent: 'space-between',
                minWidth: '300px',
                mt: 2,
                pl: '30px',
                pr: '9px'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
                <Typography sx={{ fontWeight: 'bold' }}>Subtotal</Typography>
                <Typography sx={{ color: theme.palette.primary.main }}>${subtotal}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
                <Typography sx={{ fontWeight: 'bold' }}>GST (10%)</Typography>
                <Typography sx={{ color: theme.palette.primary.main }}>${(subtotal * 0.1).toFixed(2)}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography sx={{ fontWeight: 'bold' }}>Total (inc. GST)</Typography>
                <Typography sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>${(subtotal * 1.1).toFixed(2)}</Typography>
              </Box>
            </Box>
          )}
        </FormControl>
      )}
    </>
  );
};

export default Tests;
