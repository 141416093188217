import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Dialog as D, DialogTitle, DialogContent, IconButton } from '@mui/material';
import Iconify from '../iconify';

const BootstrapDialog = styled(D)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <Iconify icon="iconamoon:close-thin" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function Dialog(props) {
  const { open, onClose, title, children } = props;

  return (
    <BootstrapDialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent>{children}</DialogContent>
    </BootstrapDialog>
  );
}
