import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardContent, Grid, TextField, Typography, InputAdornment } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import Test from '../../utils/test';
import { useAddTestMutation } from '../../slices/api';

export default function NewTest() {
  const navigate = useNavigate();
  const [addTest, { isSuccess, isError }] = useAddTestMutation();

  const formik = useFormik({
    initialValues: Test.schema.cast(),
    validationSchema: Test.schema,
    onSubmit: values => {
      addTest(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      navigate('/dashboard/admin/tests');
    }
    if (isError) {
      formik.setSubmitting(false);
    }
  }, [formik, isError, navigate, isSuccess]);

  return (
    <>
      <Helmet>
        <title> New Test - {process.env.REACT_APP_ORG} </title>
      </Helmet>
      <Grid container justifyContent="start">
        <Grid item md={6}>
          <Typography variant="h4" gutterBottom>
            New Test
          </Typography>
          <Card md={{ m: 2 }}>
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="name"
                      name="name"
                      label="Name"
                      variant="standard"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="desc"
                      name="desc"
                      label="Description"
                      variant="standard"
                      value={formik.values.desc}
                      onChange={formik.handleChange}
                      error={formik.touched.desc && Boolean(formik.errors.desc)}
                      helperText={formik.touched.desc && formik.errors.desc}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      fullWidth
                      margin="dense"
                      id="price"
                      name="price"
                      label="Price"
                      variant="standard"
                      type="number"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <Grid container justifyContent="start">
                      <Grid item md={6}>
                        <LoadingButton loading={formik.isSubmitting} variant="outlined" color="primary" type="submit">
                          <span>Save</span>
                        </LoadingButton>
                      </Grid>
                      <Grid item md={6}>
                        <Button color="error" variant="outlined" onClick={() => navigate('/dashboard/admin/tests')}>
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
