import { Card } from '@mui/material';

import { useGetSupplierQuery, useGetTestsQuery } from '../../../slices/api';
import RequestDetailCardContent from './DetailCardContent';

const Review = props => {
  const { formik } = props;
  const requestToReview = {};

  const { data: supplier } = useGetSupplierQuery(formik.values.supplierId);
  const { data: tests } = useGetTestsQuery();

  if (supplier) {
    requestToReview.supplier = supplier;
  }

  if (tests) {
    requestToReview.tests = tests.filter(test => formik.values.tests.findIndex(t => t.id === test.id.toString()) > -1);
  }

  requestToReview.candidate = {
    ...formik.values.candidate,
  };

  return requestToReview.supplier && requestToReview.tests ? (
    <Card>
      <RequestDetailCardContent request={requestToReview} />
    </Card>
  ) : null;
};

export default Review;
