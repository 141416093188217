import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

/*
  NOT IN USE
  this slice will be used to implement auto-reauth when token expiring
*/
const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setPageNumber(state, action) {
      state[action.payload.entity] = action.payload.value;
    },
  },
});

export const { setPageNumber } = paginationSlice.actions;
export default paginationSlice.reducer;
