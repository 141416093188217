import { Box, FormControl, FormLabel } from '@mui/material';
import { sentenceCase } from 'change-case';

import Label from '../../../components/label';

const Status = ({ formik, showFormLabel = false }) => {
  return (
    <>
      <FormControl margin="normal" component="fieldset" variant="standard">
        {showFormLabel ? <FormLabel component="legend">Status</FormLabel> : null}
        <Box sx={{ my: 1 }}>
          <Label color={'success'}>{sentenceCase(formik.values.status)}</Label>
        </Box>
      </FormControl>
    </>
  );
};

export default Status;
