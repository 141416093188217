import { createSlice } from '@reduxjs/toolkit';

const initialState = { isAuthenticated: null };

/*
  this slice is used to implement auto-reauth when token expiring
*/
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth(state) {
      state.isAuthenticated = false;
    },
  },
});

export const { resetAuth } = authSlice.actions;
export default authSlice.reducer;
